import React, {useContext, useEffect, useState} from 'react';
import './BuyDetails.scss';
import {observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';
import {Client, clientsStoreContext} from '../../store/components/clientsStore';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    Row
} from 'reactstrap';
import {clone, extraIconsMap} from '../../utils/functions';
import {
    conditions,
    RealEstateExtra,
    realEstatesStoreContext,
    realEstateTypes
} from '../../store/components/realEstatesStore';
import moment from 'moment';
import {ContentEditableEvent} from 'react-simple-wysiwyg';
import FieldPhone from '../../components/FieldPhone/FieldPhone';

type ExtraActive = {
    [key: string]: boolean;
}

type ClientState = {
    clientState: Client,
    extras: RealEstateExtra[],
    extrasActive: ExtraActive,
}

const BuyDetails = observer(() => {
    let { id } = useParams();
    const clientsStoreCtx = useContext(clientsStoreContext);
    const realEstateStoreCtx = useContext(realEstatesStoreContext);
    const [clientState, setClientState] = useState({
        clientState: {} as Client,
        extras: [],
        extrasActive: {} as ExtraActive
    });
    const [clientDefault, setClientDefault] = useState({} as Client);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        clientsStoreCtx.loadStore().then((result) => {
            realEstateStoreCtx.getRealEstateExtras().then((result2) => {
                const client = result.find((client: Client) => client.id === id);
                setClientState({
                    clientState: client,
                    extras: result2,
                    extrasActive: result2.reduce((acc: ExtraActive, extra: RealEstateExtra) => {
                        acc[extra.id] = !!([].concat(client.extras) || []).find((id: number) => id === extra.id);
                        return acc;
                    }, {} as ClientState)
                })
                setClientDefault(client);
                setLoading(false);
            })
        });
    }, [clientsStoreCtx]);

    if (isSubmitting) {
        return <div>Save buy details...</div>
    }

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    const getExtrasActive = () => {
        return Object.keys(extrasActive).reduce((acc, id) => {
            if (extrasActive[id]) {
                acc.push(+id);
            }
            return acc;
        }, [] as number[]);
    }

    const hasSubmitDisabled = () => {
        const defaultStateJSON = JSON.stringify(clientDefault);
        const currentState = clone(clientState.clientState);
        currentState.extras = getExtrasActive();
        const currentStateJSON = JSON.stringify(currentState);
        return defaultStateJSON === currentStateJSON;
    }

    const merge = (data: any) => {
        const state = {...clientState.clientState, ...data};
        setClientState({...clientState, ...{clientState: state}});
    }

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        merge({[field]: event.target.value});
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) => {
        let value = event.target.value;
        merge({[field]: value});
    }

    const handleChangeExtraState = (extra: RealEstateExtra) => {
        return;

        // const extrasActive = {...clientState.extrasActive, ...{[extra.id]: !clientState.extrasActive[extra.id]}};
        // setClientState({...clientState, ...{extrasActive}});
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setSubmitting(true);
        const state = clone(clientState.clientState);
        state.extras = getExtrasActive();
        await clientsStoreCtx.updateClient(state.id, state);
        window.location.reload();
    }

    const client = clientState.clientState;
    const extras = clientState.extras;
    const extrasActive = clientState.extrasActive;
    const isDisabledSubmit = hasSubmitDisabled();

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader className="card-header-details">
                        <Row>
                            <Col sm="12" md="4" lg="6">
                                ID: {client.id}
                            </Col>
                            <Col sm="8" md="5" lg="5">
                                <Row className="g-2 align-items-center justify-content-between">
                                    <Col sm="6" className="ta-right">Status:</Col>
                                    <Col sm="6">
                                        <Input
                                            bsSize="sm"
                                            id="status"
                                            name="status"
                                            type="select"
                                            defaultValue={client.status}
                                            onChange={(event) => handleChangeSelect(event, 'status')}
                                        >
                                            <option value={'new'}>New</option>
                                            <option value={'contacted'}>Contacted</option>
                                            <option value={'active'}>Active</option>
                                            <option value={'declined'}>Declined</option>
                                            <option value={'closed'}>Closed</option>
                                            <option value={'completed'}>Completed</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="8" md="3" lg="1">
                                <Button size="sm" type="submit" color={isDisabledSubmit ? "" : "warning"} disabled={isDisabledSubmit}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className="details-card">
                            <Col sm="12">
                                <Card body>
                                    <CardTitle tag="h4">
                                        Client information
                                    </CardTitle>
                                    <Row>
                                        <Col sm="4">
                                            <label htmlFor="fullName" className="form-label">Client</label>
                                            <Input
                                                disabled
                                                bsSize="sm"
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                value={client.fullName ?? ''}
                                                onChange={(event) => handleChangeTextField(event, 'fullName')}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Input
                                                disabled
                                                bsSize="sm"
                                                id="email"
                                                name="email"
                                                type="text"
                                                value={client.email || ''}
                                                onChange={(event) => handleChangeTextField(event, 'email')}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <InputGroup size="sm">
                                                <FieldPhone
                                                    disabled={true}
                                                    id="phone"
                                                    type="tel"
                                                    placeholder={'_________'}
                                                    value={client.phone ?? ''}
                                                    onChange={(event) => handleChangeTextField(event, 'phone')}
                                                    name={'phone'}
                                                    maxLength={9}
                                                    phoneCode={client.phone ?? ''}
                                                    onChangePhoneCode={(phoneCode) => merge({phoneCode: phoneCode})}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="row-card-description">
                                        <Col sm="6">
                                            <label htmlFor="description" className="form-label">Request description</label>
                                            <Input
                                                disabled
                                                id="description"
                                                name="description"
                                                type="textarea"
                                                value={client.description || ''}
                                                onChange={(event) => handleChangeTextField(event, 'description')}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <Input
                                                id="notes"
                                                name="notes"
                                                type="textarea"
                                                value={client.notes || ''}
                                                onChange={(event) => handleChangeTextField(event, 'notes')}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="details-card">
                            <Col sm="12">
                                <Card body>
                                    <CardTitle tag="h4">
                                        Buy details
                                    </CardTitle>
                                    <Row>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="municipality" className="form-label">Municipality</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="municipality"
                                                name="municipality"
                                                type="text"
                                                value={client.municipality || ''}
                                            />
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="realEstateType" className="form-label">Type</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="realEstateType"
                                                name="realEstateType"
                                                type="select"
                                                defaultValue={client.realEstateType}
                                                onChange={(event) => handleChangeSelect(event, 'realEstateType')}
                                            >
                                                {realEstateTypes.map((realEstateType) => {
                                                    return (<option value={realEstateType.value} key={'type_' + realEstateType.id}>
                                                        {realEstateType.name}
                                                    </option>)
                                                })}
                                            </Input>
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="condition" className="form-label">Condition</label>
                                            <Input
                                                disabled
                                                bsSize="sm"
                                                id="condition"
                                                name="condition"
                                                type="select"
                                                defaultValue={client.condition ?? ''}
                                                onChange={(event) => handleChangeSelect(event, 'condition')}
                                            >
                                                {conditions.map((condition) => {
                                                    return (<option value={condition.value} key={'condition_' + condition.id}>
                                                        {condition.name}
                                                    </option>)
                                                })}
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="livingArea" className="form-label">Living area</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="livingArea"
                                                name="livingArea"
                                                type="text"
                                                value={client.livingArea || ''}
                                            />
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="bathroomCount" className="form-label">Bedrooms</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="bathroomCount"
                                                name="bathroomCount"
                                                type="text"
                                                value={client.bathroomCount || ''}
                                            />
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="bedroomCount" className="form-label">Bathrooms</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="bedroomCount"
                                                name="bedroomCount"
                                                type="text"
                                                value={client.bedroomCount || ''}
                                            />
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="bedroomCount" className="form-label">Price</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="price"
                                                name="price"
                                                type="text"
                                                value={client.price || ''}
                                            />
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="buyDeadline" className="form-label">Buy deadline</label>
                                            <Input
                                                bsSize="sm"
                                                disabled
                                                id="buyDeadline"
                                                name="buyDeadline"
                                                type="text"
                                                value={client.buyDeadline || ''}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col sm="12" className="mt-24">
                                <Row>
                                    <Col sm="12" className="ml-16 mb-8">
                                        Extras
                                    </Col>
                                </Row>
                                <div className="user-extras">
                                    {extras.map((extra: RealEstateExtra) => {
                                        const isActive = extrasActive[extra.id];

                                        if (isActive) {
                                            return <Badge color={'success'} key={'extra_' + extra.id} onClick={() => handleChangeExtraState(extra)}>
                                                {/*@ts-ignore*/}
                                                <i className={`${extraIconsMap[extra.id]}`}/>
                                                {extra.name}
                                            </Badge>
                                        }

                                        return (<Badge color={'light'} className="text-dark" key={'extra_' + extra.id} onClick={() => handleChangeExtraState(extra)}>
                                            {/*@ts-ignore*/}
                                            <i className={`${extraIconsMap[extra.id]}`}/>
                                            {extra.name}
                                        </Badge>);
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
        </Container>
    );
})

export default BuyDetails;
