import React from 'react';
import {Badge} from 'reactstrap';
import './BuyStatus.scss';

type BuyStatusProps = {
    status: string;
}

const BuyStatus: React.FC<BuyStatusProps> = (props) => {
    const getColor = () => {
        const statusMap = {
            'new': 'success',
            'active': 'success',
            'paused': 'warning',
            'contacted': 'info',
            'closed': 'secondary',
            'declined': 'secondary',
            'completed': 'success',
        }

        // @ts-ignore
        return statusMap[props.status];
    }
    return (<Badge color={getColor()}>
        {props.status}
    </Badge>)
}

export default BuyStatus;
