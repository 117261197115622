import {Navigate, Outlet, useLocation} from 'react-router-dom';
import React from 'react';
import './ProtectedRoute.scss';
import Header from '../Header/Header';

type ProtectedRouteProps = {
    children?: JSX.Element;
    isAuthorized: boolean;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    const location = useLocation();
    if (!props.isAuthorized) {
        // Redirect them to the /sign page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }
    return (<>
        <Header isAuthorized={props.isAuthorized}/>
        <div className="content">
            <Outlet/>
        </div>
    </>);
}

export default ProtectedRoute;
