import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {auth} from '../services/Firebase';
import {api} from '../services/Api';

class Store {
    isLoading: boolean = true;
    isAuthorized: boolean = false;
    firebaseAuth = auth;
    userPreferences: UserPreferences = {} as UserPreferences;
    userAuthorized = {} as any;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isAuthorized: observable,
            userPreferences: observable,
            userAuthorized: observable,
            setLoading: action,
            setAuthorized: action,
            setUserAuthorized: action,
            isAdmin: action,
        });
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    async setAuthorized(user: any) {
        this.isAuthorized = !!user;
        if (this.isAuthorized) {
            api.token = await user.auth.currentUser.getIdToken(true);
            this.setUserAuthorized(user);
        }
        this.setLoading(false);
    }

    setUserAuthorized(user: any) {
        this.userAuthorized = user;
    }

    setUserPreferences(userPreferences: UserPreferences) {
        this.userPreferences = userPreferences;
    }

    async getUserPreferences(id: string) {
        try {
            const userPreferences = await api.request('GET', `/user/${id}`);
            this.setUserPreferences(userPreferences);
            return userPreferences;
        } catch (err) {}
    }

    isAdmin() {
        return this.userPreferences.role === 'admin';
    }
}

type UserPreferences = {
    id: string;
    googleId: string;
    role: string;
}

export const store = new Store();
export const storeContext = createContext(store);
