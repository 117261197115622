import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

class RealEstatesStore {
    clients: Client[] = [];

    constructor() {
        makeObservable(this, {
            clients: observable,
            setClients: action,
            getClients: action,
            getClient: action,
            loadStore: action,
        });
    }

    getClients() {
        return this.clients;
    }

    setClients(clients: Client[]) {
        this.clients = clients;
    }

    async getClient(id: string) {
        const client = this.clients.find((cl) => cl.id === id);
        if (client) {
            return client;
        }

        try {
            return await api.request('GET', `/client/${id}`);
        } catch (err) {}
    }

    async updateClient(id: string, client: Client) {
        try {
            return await api.request('PUT', `/client/${id}`, client);
        } catch (err) {}
    }

    async loadStore() {
        try {
            const clients = await api.request('GET', '/clients');
            this.setClients(clients);
            return clients;
        } catch (err) {
        }
    }
}

export type Client = {
    id?: string;
    realEstateType: string;
    condition: string | null;
    municipality: string | null;
    livingArea: string | null;
    bathroomCount: string | null;
    bedroomCount: string | null;
    extras: number[];
    price: string | null;
    buyDeadline: string | null;
    fullName: string | null;
    phone: string | null;
    phoneCode: string | null;
    email: string | null;
    description: string | null;
    notes: string | null;
    status: string;
    createdAt: string;
}

export const clientsStore = new RealEstatesStore();
export const clientsStoreContext = createContext(clientsStore);
