import React, {useContext, useEffect, useState} from 'react';
import './Buys.scss';
import {observer} from 'mobx-react';
import {Alert, Badge, Table} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {clientsStoreContext} from '../../store/components/clientsStore';
import BuyStatus from '../../components/BuyStatus/BuyStatus';


const Buys = observer(() => {
    const clientsStoreCtx = useContext(clientsStoreContext);
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        clientsStoreCtx.loadStore().then((result) => {
            setClients(result);
            setLoading(false);
        });
    }, [clientsStoreCtx]);

    const handleNavigateToClientDetails = (id: string) => {
        navigate(`/buy/${id}`);
    }

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    if (!clients || !clients.length) {
        return (
            <div style={{margin: '16px 12px'}}>
                <Alert color="warning">
                    No data for display
                </Alert>
            </div>
        )
    }
    return (
        <Table bordered hover responsive size={'sm'}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Municipality</th>
                    <th>Price</th>
                    <th>Buy deadline</th>
                    <th>Created</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            {
                clients.map((client: any, index: number) => (
                    <tr key={client.id} onClick={() => handleNavigateToClientDetails(client.id)}>
                        <td>{client.id}</td>
                        <td>{client.fullName}</td>
                        <td>{client.phoneCode}{client.phone}</td>
                        <td>{client.email}</td>
                        <td>{client.municipality}</td>
                        <td>{client.price}</td>
                        <td>{client.buyDeadline}</td>
                        <td>{client.createdAt ? moment(client.createdAt).format('DD MMM YYYY, hh:mm') : ''}</td>
                        <td>
                            <BuyStatus status={client.status} />
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    );
})

export default Buys;
