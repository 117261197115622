import {makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

class RealEstatesStore {
    realEstates: RealEstate[] = [];
    realEstateExtras: RealEstateExtra[] = [];

    constructor() {
        makeObservable(this, {
            realEstates: observable,
        });
    }

    setRealEstates(realEstates: RealEstate[]) {
        this.realEstates = realEstates;
    }

    setRealEstateExtras(realEstateExtras: RealEstateExtra[]) {
        this.realEstateExtras = realEstateExtras;
    }

    addRealEstate(realEstate: any) {
        this.realEstates.push(realEstate);
    }

    async uploadRealEstateImages(id: string, form: HTMLFormElement) {
        try {
            await api.request('POST',`/realEstate/${id}/upload`, form, undefined, {headers: {'Content-Type': 'multipart/form-data'}})
        } catch (err) {}
    }

    async removeRealEstateImages(id: string, files: string[]) {
        try {
            await api.request('POST', `/realEstate/${id}/unupload`, {urls: files});
        } catch (err) {}
    }

    async getRealEstate(id: string) {
        const realEstate = this.realEstates.find((realEstate) => realEstate.id === id);
        if (realEstate) {
            return realEstate;
        }

        try {
            const realEstate = await api.request('GET', `/realEstate/${id}`);
            this.addRealEstate(realEstate);
            return realEstate;
        } catch (err) {}
    }

    async getRealEstateExtras() {
        if (this.realEstateExtras.length > 0) {
            return this.realEstateExtras;
        }

        try {
            const realEstateExtras = await api.request('GET', 'realEstate/extras');
            this.setRealEstateExtras(realEstateExtras);
            return realEstateExtras;
        } catch (err) {}
    }

    async loadStore(params: {email: string, phone: string, fullName: string, statuses: string[]}) {
        try {
            const {email, phone, fullName, statuses} = params;
            let query = new URLSearchParams({email, phone, fullName}).toString();
            if (statuses) {
                statuses.forEach((status) => query += `&statuses[]=${status}`);
            }
            const realEstates = await api.request('GET', '/realEstates/crm?' + query);
            this.setRealEstates(realEstates);
            return realEstates;
        } catch (err) {}
    }

    async updateRealEstate(id: string, realEstate: RealEstate) {
        try {
            return api.request('PUT', `/realEstate/${id}`, realEstate);
        } catch (err) {}
    }
}

export type RealEstate = {
    id?: string;
    privacy: boolean;
    municipality: string;
    country: string;
    street: string;
    postcode: string;
    realEstateType: string;
    presentationAddress: string;
    presentationManualAddress: string;
    houseNumber: string;
    ladder: string | null;
    floor: string;
    door: string;
    cadastralNumber: string | null;
    livingArea: number | null;
    plotArea: number | null;
    bathroomCount: number;
    bedroomCount: number;
    buildingYear: number | null;
    condition: string;
    energyCertificate: boolean | null;
    energyClass: string | null;
    extras: number[];
    requestType: string;
    fullName: string;
    email: string;
    emailAdditional: string;
    phone: string;
    phoneAdditional: string;
    phoneCode: string;
    phoneCodeAdditional: string;
    requestDate: string;
    requestTimeStart: string;
    requestTimeEnd: string;
    description: string;
    notes?: string;
    status: string;
    realEstateMarketingName?: string | null;
    realEstateMarketingNameES?: string | null;
    realEstateMarketingDescription?: string | null;
    realEstateMarketingDescriptionES?: string | null;
    previews?: Preview[],
    finalPrice?: number | null;
    clientPrice?: number | null;
    catalogPrice?: number | null;
    longitude?: number | null;
    latitude?: number | null;
    isBestOffer?: boolean;
}

export type Preview = {
    imagePath: string;
    imageUrl: string;
}

export type RealEstateExtra = {
    id: number;
    name: string;
}

export type Condition = {
    id: number;
    name: string;
    value: string
}

export const conditions: Condition[] = [
    {id: 1, name: 'New', value: 'new'},
    {id: 2, name: 'Good condition', value: 'good condition'},
    {id: 3, name: 'Originally', value: 'originally'},
    {id: 4, name: 'Renovated', value: 'renovated'},
    {id: 5, name: 'To reform', value: 'to reform'},
]

export const realEstateTypes: RealEstateType[] = [
    {id: 1, name: 'Apartment', value: 'apartment', iconName: 'icon-valencia'},
    {id: 2, name: 'House', value: 'house', iconName: 'icon-home'},
    {id: 3, name: 'Commercial', value: 'commercial', iconName: 'icon-building'},
    {id: 4, name: 'Land', value: 'land', iconName: 'icon-earth'},
]

export type RealEstateType = {
    id: number;
    name: string;
    value: string;
    iconName: string;
}

export type Municipality = {
    id: number;
    name: string;
    state_id: number;
    state_code: string;
    state_name: string,
    country_id: number,
    country_code: string,
    country_name: string,
    latitude: string,
    longitude: string,
    wikiDataId: string,
    meta: {
        revision: number,
        created: number,
        version: number
    },
    $loki: number
}

export type Street = {
    presentationAddress: string;
    street: string;
    postcode: string;
    district: string;
    area: string;
    municipality: string;
}

export const realEstatesStore = new RealEstatesStore();
export const realEstatesStoreContext = createContext(realEstatesStore);
