import React from 'react';
import {Badge} from 'reactstrap';
import './RealEstateStatus.scss';

type RealEstateStatusStatusProps = {
    status: string;
}
const RealEstateStatus: React.FC<RealEstateStatusStatusProps> = (props) => {
    const getColor = () => {
        const statusMap = {
            'new': 'success',
            'paused': 'warning',
            'published': 'info',
            'closed': 'secondary',
            'sales': 'success',
        }

        // @ts-ignore
        return statusMap[props.status];
    }
    return (<Badge color={getColor()}>
        {props.status}
    </Badge>)
}

export default RealEstateStatus;
