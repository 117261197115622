import React, {SyntheticEvent, useContext, useState} from 'react';
import './Signin.scss';
import {auth} from '../../services/Firebase';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {storeContext} from '../../store/store';

function Signin() {
    const storeCtx = useContext(storeContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmitForm = (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => storeCtx.setAuthorized(userCredential))
            .catch((error) => {
                console.log('[Singin.tsx][handleSubmitForm][error]: ', error.code, error.message)
                storeCtx.setAuthorized(null).then(() => {});
                window.location.href = '/';
            });
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    return (
        <Container style={{marginTop: '24px'}}>
            <Row>
                <Col sm={{
                    offset: 4,
                    size: 4
                }}>
                    <Form onSubmit={handleSubmitForm}>
                        <FormGroup>
                            <Label for="email">
                                Email
                            </Label>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={handleChangeEmail}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">
                                Password
                            </Label>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={handleChangePassword}
                            />
                        </FormGroup>
                        <Button>
                            Sign in
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Signin;
