import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

class RealEstatesStore {
    feedbacks: Feedback[] = [];

    constructor() {
        makeObservable(this, {
            feedbacks: observable,
            setFeedbacks: action,
            getFeedbacks: action,
            getFeedback: action,
            loadStore: action,
        });
    }

    getFeedbacks() {
        return this.feedbacks;
    }

    setFeedbacks(clients: Feedback[]) {
        this.feedbacks = clients;
    }

    async getFeedback(id: string) {
        const feedback = this.feedbacks.find((fb) => fb.id === id);
        if (feedback) {
            return feedback;
        }

        try {
            return await api.request('GET', `/feedback/${id}`);
        } catch (err) {}
    }

    async loadStore() {
        try {
            const feedbacks = await api.request('GET', '/feedbacks');
            this.setFeedbacks(feedbacks);
            return feedbacks;
        } catch (err) {
        }
    }
}

export type Feedback = {
    id?: string;
    privacy: boolean;
    type: string;
    fullName: string | null;
    phone: string | null;
    email: string | null;
    description: string | null;
    notes: string | null;
    status: string;
    createdAt?: string;
}

export const feedbacksStore = new RealEstatesStore();
export const feedbacksStoreContext = createContext(feedbacksStore);
