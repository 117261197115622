import React, {useContext, useEffect, useRef, useState} from 'react';
import citiesJSON from './MunicipalitiesCombobox.json';
import './MunicipalitiesCombobox.scss';
import Popover from '../Popover/Popover';
import {clone} from '../../utils/functions';
import {Municipality} from '../../store/components/realEstatesStore';

type CitiesComboboxProps = {
    initBy?: string | null;
    onSelect: (municipality: Municipality | null) => void;
    onOpen?: () => void;
    mode?: string;
    icon?: string;
}

const MunicipalitiesCombobox: React.FC<CitiesComboboxProps> = (props) => {
    const comboboxRef = useRef<HTMLInputElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [state, setState] = useState({
        municipalities: clone(citiesJSON.municipalities),
        searchTerm: '',
        initBy: null,
    });
    const getMunicipality = (searchTerm: string) => {
        const municipality = citiesJSON.municipalities.find((municipality) => municipality.name === searchTerm);
        return municipality ? municipality : null;
    }
    const getMunicipalities = (searchTerm: string) => {
        const municipalities = clone(citiesJSON.municipalities);
        return municipalities.filter((municipality: Municipality) => !!~municipality.name.toLowerCase().indexOf(searchTerm.toLowerCase()))
    }
    const merge = (data: any) => setState({...state, ...data});

    useEffect(() => {
        if (props.initBy) {
            const municipality = getMunicipality(props.initBy);
            merge({municipalities: getMunicipalities(props.initBy), initBy: municipality, searchTerm: municipality ? municipality.name : ''});
        }
    }, [props.initBy]);

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        const municipalities = clone(citiesJSON.municipalities);
        if (!searchTerm) {
            return setState({municipalities, searchTerm: '', initBy: null,});
        }

        return setState({
            municipalities: getMunicipalities(searchTerm),
            searchTerm: searchTerm,
            initBy: null,
        });
    }

    const handleSelectMunicipality = (municipality: Municipality) => {
        merge({municipalities: [municipality], searchTerm: municipality.name, initBy: municipality});
        props.onSelect(municipality);
        comboboxRef.current && comboboxRef.current.blur();
    }

    const handleResetMunicipality = (event: React.ChangeEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
        merge({municipalities: clone(citiesJSON.municipalities), searchTerm: '', initBy: null});
        props.onSelect(null);
        comboboxRef.current && comboboxRef.current.blur();
        // @ts-ignore
        setTimeout(() => popoverRef.current && popoverRef.current.close(), 50);
    }

    const handleBlur = () => {
        merge({municipalities: getMunicipalities(state.searchTerm), searchTerm: state.searchTerm ?? '', initBy: state.initBy ?? null});
        props.onSelect(state.initBy ?? null);
        comboboxRef.current && comboboxRef.current.blur();
    }

    return (<Popover
        onOutsideClick={handleBlur}
        onClose={handleBlur}
        className="municipality-combobox"
        ref={popoverRef}
        content={<>
            {
                state.municipalities.map((municipality: Municipality) =>
                <button type="button" data-button-type={'close'} key={municipality.id} onMouseDown={() => handleSelectMunicipality(municipality)}>
                    {municipality.name}
                </button>)
            }
            {
                state.municipalities.length === 0 &&
                <>
                    <div/>
                    <div className="no-match">No match found</div>
                    <div/>
                </>
            }
        </>}>
        <div className="municipality-combobox__trigger">
            <input
                ref={comboboxRef}
                onChange={handleFilter}
                value={state.searchTerm}
                autoComplete={'off'}
                type="text"
                className="field-municipality form-control-sm form-control"
            />
            {
                (!props.mode || props.mode !== 'clear') && state.initBy && <i className="icon-close field-postfix" onClick={handleResetMunicipality}/>
            }
        </div>
    </Popover>)
}

export default MunicipalitiesCombobox;
