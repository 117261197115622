import React, {useContext, useEffect, useState} from 'react';
import './Feedbacks.scss';
import {observer} from 'mobx-react';
import {Alert, Badge, Table} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {feedbacksStoreContext} from '../../store/components/feedbacksStore';


const Feedbacks = observer(() => {
    const feedbacksStoreCtx = useContext(feedbacksStoreContext);
    const [feedbacks, setFeedbacks] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        feedbacksStoreCtx.loadStore().then((result) => {
            setFeedbacks(result);
            setLoading(false);
        });
    }, [feedbacksStoreCtx]);

    // const handleNavigateToRealEstateDetails = (id: string) => {
    //     navigate(`/realestate/${id}`);
    // }

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    if (!feedbacks || !feedbacks.length) {
        return (
            <div style={{margin: '16px 12px'}}>
                <Alert color="warning">
                    No data for display
                </Alert>
            </div>
        )
    }
    return (
        <Table bordered responsive size={'sm'}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Description</th>
                    <th>Created</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            {
                feedbacks.map((feedback: any, index: number) => (
                    <tr key={feedback.id}>
                        <td>{feedback.id}</td>
                        <td>{feedback.fullName}</td>
                        <td>{feedback.type}</td>
                        <td>{feedback.phoneCode}{feedback.phone}</td>
                        <td>{feedback.email}</td>
                        <td>{feedback.description}</td>
                        <td>{feedback.createdAt ? moment(feedback.createdAt).format('DD MMM YYYY, hh:mm') : ''}</td>
                        <td><Badge>{feedback.status}</Badge></td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    );
})

export default Feedbacks;
