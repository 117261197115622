import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import {API_URL} from '../configs/api';

class Api {
    token: string | null = null;
    language: string | null = null;
    api: AxiosInstance = axios.create({
        baseURL: API_URL || '',
    });
    constructor() {
        this.api.interceptors.response.use(
            this.handleSuccess.bind(this),
            this.handleError.bind(this),
        );
    }

    request(
        method: string,
        url: string,
        data?: any,
        signal?: AbortSignal,
        config?: any
    ): Promise<any> {
        let requestConfig: AxiosRequestConfig & {
            headers: {Authorization?: string};
        } = {
            method,
            url,
            responseType: 'json',
            headers: {},
            withCredentials: true,
        };
        if (config) {
            requestConfig = {...requestConfig, ...config};
        }
        if (this.token) {
            requestConfig.headers.Authorization = `Bearer ${this.token}`;
        }
        if (data) {
            requestConfig.data = data;
        }
        if (method === 'GET' && !url.includes('?')) {
            requestConfig.url += '?random=' + Math.random();
        } else if (method === 'GET' && url.includes('?')) {
            requestConfig.url += '&random=' + Math.random();
        }
        if (method === 'GET' && signal) {
            requestConfig.signal = signal;
        }
        return this.api.request(requestConfig);
    }

    handleSuccess(response: AxiosResponse) {
        return Promise.resolve(response.data);
    }

    async handleError(error: AxiosError) {
        return Promise.reject(error && error.response);
    }
}

export type IApiConfig = {
    apiPrefix: string;
    apiUrl: string;
};

export const api = new Api();
