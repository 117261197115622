import React, {useState, useRef, useEffect, useImperativeHandle, forwardRef} from 'react';
import './Popover.scss';

type PopoverProps = {
    children: JSX.Element;
    content: JSX.Element;
    className?: string;
    width?: number;
    onClose?: () => void;
    onOpen?: () => void;
    onOutsideClick?: () => void;
    onApply?: () => void;
    onReset?: () => void;
    ref?: any;
};

const Popover: React.FC<PopoverProps> = forwardRef((props, ref) => {
    const [isVisible, setIsVisible] = useState(false); // Manages the visibility state of the popover
    const popoverRef = useRef<any>(null); // Reference to the popover element
    const triggerRef = useRef<any>(null); // Reference to the button element that triggers the popover

    const toggleVisibility = () => {
        const visible = !isVisible;
        setIsVisible(visible);
        if (visible) {
            props.onOpen && props.onOpen();
        } else {
            props.onClose && props.onClose();
        }
    };

    const close = () => {
        setIsVisible(false);
        props.onClose && props.onClose();
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !triggerRef.current.contains(event.target)
            ) {
                setIsVisible(false);
                props.onOutsideClick && props.onOutsideClick();
            }

            const isClickByButtonReset = event.target.nodeName === 'BUTTON' && event.target.getAttribute('data-button-type') === 'reset'
            if (
                popoverRef.current &&
                popoverRef.current.contains(event.target) &&
                isClickByButtonReset
            ) {
                setIsVisible(false);
                props.onReset && props.onReset();
            }

            const isClickByButtonApply = event.target.nodeName === 'BUTTON' && event.target.getAttribute('data-button-type') === 'apply'
            if (
                popoverRef.current &&
                popoverRef.current.contains(event.target) &&
                isClickByButtonApply
            ) {
                setIsVisible(false);
                props.onApply && props.onApply();
            }

            const isClickByButtonClose = event.target.nodeName === 'BUTTON' && event.target.getAttribute('data-button-type') === 'close'
            if (
                popoverRef.current &&
                popoverRef.current.contains(event.target) &&
                isClickByButtonClose
            ) {
                event.preventDefault();
                event.stopImmediatePropagation();
                setTimeout(() => {
                    setIsVisible(false);
                }, 50);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [props.onOutsideClick]);

    useImperativeHandle(ref, () => ({
        close() {
            close();
        }
    }));

    return (
        <div className={`popover-container ${props.className ? props.className : ''}`}>
            <div ref={triggerRef} onClick={toggleVisibility} className="popover-trigger">
                {props.children}
            </div>
            {isVisible && (<div
                    id="popover-content"
                    ref={popoverRef}
                    className="popover-content"
                    role="dialog"
                    aria-modal="true"
                    style={{width: props.width || '100%'}}
                >
                    {props.content}
                </div>
            )}
        </div>
    );
});

export default Popover;
